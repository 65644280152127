import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Container, Row, Col } from "reactstrap"
import SanityBlockContent from "@sanity/block-content-to-react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Navbar2 from "../components/Navbar2"
import { MediaQueries } from "../utils/responsive"

const StyledMainBlogPostRow = styled(Row)`
  margin-top: 80px;
  @media ${MediaQueries.mobileUp} {
    margin-top: 0;
  }
  .titleCol {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .background {
      background: ${props => props.theme.colors.purple};
      a:hover {
        text-decoration: none;
        cursor: pointer;
      }
      h2 {
        margin: 0;
        padding: 1rem;
        min-height: 110px;
      }
    }
  }
  .blog-link {
    color: ${props => props.theme.colors.white};
    &:hover {
      text-decoration: none;
    }
  }
`

const StyledOtherBlogPostRow = styled(Row)`
  .otherPostImage {
    max-height: 400px;
  }
  .background {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    background: ${props => props.theme.colors.purpleOp};
    h2 {
      margin: 0;
      min-height: 110px;
      padding: 1rem;
    }
  }
  .blog-link {
    color: ${props => props.theme.colors.white};
    &:hover {
      text-decoration: none;
    }
  }
`

const ArchivePage = props => {
  const { i18n } = useTranslation("blog")
  const language = i18n.language
  const mainPost = props.data.posts.edges[0]?.node
  const otherPosts = props.data.posts.edges.slice(1)

  return (
    <Layout alternateLink={props.alternateLinks}>
      <SEO title="Justanatomy.com | Blog" />
      <Container>
        <StyledMainBlogPostRow className="mb-lg-3">
          <Col xs={12} lg={8} className="px-0">
            <GatsbyImage image={getImage(mainPost?.mainImage.asset)} />
          </Col>
          <Col xs={12} lg={4} className="titleCol">
            <div className="background">
              <Link
                to={mainPost?._rawSlug[language].current}
                className="blog-link"
              >
                <h2 className="pl-5 pt-5">{mainPost?.title.translate}</h2>
              </Link>
            </div>
            <SanityBlockContent
              blocks={mainPost?._rawBody[language][0]}
              className="blogText pl-5 pr-3 py-3"
              renderContainerOnSingleChild={true}
            />
          </Col>
        </StyledMainBlogPostRow>
        <StyledOtherBlogPostRow className="mb-lg-5">
          {otherPosts &&
            otherPosts.map((post, i) => (
              <Col
                xs={12}
                lg={6}
                key={post.node._id}
                className={i % 2 !== 0 ? "py-3 pr-md-0" : "py-3 pl-md-0"}
              >
                <div className="position-relative h-100">
                  {post.node.mainImage && (
                    <GatsbyImage
                      className="otherPostImage h-100"
                      image={getImage(post.node.mainImage.asset)}
                      alt=""
                    />
                  )}

                  <div className="background">
                    <Link
                      to={post.node._rawSlug[language].current}
                      className="blog-link"
                    >
                      <h2 className="pl-5 pt-5">{post.node.title.translate}</h2>
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
        </StyledOtherBlogPostRow>
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12} className="px-0 position-relative">
            <Navbar2 topNav={false} narrow={true} fixed={true} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query ArchivePageQuery($language: String) {
    posts: allSanityPost(sort: { fields: _createdAt, order: DESC }) {
      edges {
        node {
          _id
          title {
            translate(language: $language)
          }
          author {
            title
          }
          publishedAt(formatString: "YYYY.MM.DD")
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          _rawBody
          _rawSlug
        }
      }
    }
  }
`

export default ArchivePage
